import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { ArticleFooterProps } from '.';
import { Share } from '../../common/share';

import { Container, Content, ShareButtons, TagItem, TagItems, TagLink } from './footer.styles';

export const ArticleFooter = ({url,title,section,topics}:ArticleFooterProps) => {
  
  return (
    <Container>
      <Content>
        <TagItems>
          {section && <TagItem><TagLink to={section.link}>{section.heading}</TagLink></TagItem>}
          {topics?.map((topic,index) => <TagItem key={index}><TagLink to={topic.link}>{topic.heading}</TagLink></TagItem>)}
        </TagItems>
        <ShareButtons url={url} title={title} />
      </Content>
    </Container>
  )
};