import React, { useRef } from 'react';
import { MediaFooter } from '../../common/mediaFooter';
import { Container, Media } from './mediaInternal.styles';

export const InlineMediaInternal = ({media}:{media:any}) => {

  return (
    <Container>
      <Media defaultSource={media} />
      <MediaFooter caption={media.title} />
    </Container>
  )
};