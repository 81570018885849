import styled from "styled-components";
import Color from 'color';
import { CONTAINER, getMediaQuery, getUnitAsPixels, getVerticalSpacingAsStyle, GRID, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { Link } from "gatsby";
import { Share } from "../../common/share";
import { getTextHover } from "../../../theme/animation";

export const Title = styled.h1`
  ${getTypography(Type.HEADING_1)}
  color: ${({ theme }) => theme.foreground.primary};
  text-align: center;
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`;

export const Container = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, VerticalSpacingVariant.LARGE)}
`

export const Content = styled.div`
  ${GRID}
`

export const MetaItems = styled.ul`
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, VerticalSpacingVariant.SMALL,{top:false})}
  color: ${({ theme }) => Color(theme.foreground.secondary).alpha(0.7)};
  text-align: center;
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`

export const MetaItem = styled.li`
  ${getTypography(Type.LABEL)}
  display: inline-block;
  
  &:after{
    content: "|";
    margin: 0 ${getUnitAsPixels()};
  }

  &:last-child{
    &:after{
      content: none;
    }
  }
`

export const MetaLink = styled(Link)`
  text-decoration: none;
  ${({theme}) => getTextHover(theme.foreground.secondary,0.7)}
`;

export const Date = styled.p`
  ${getTypography(Type.LABEL)}
  color: ${({ theme }) => Color(theme.foreground.secondary).alpha(0.7)};
  text-align: center;
  grid-column: span 4;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, VerticalSpacingVariant.SMALL,{bottom:false})}

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`

export const ShareButtons = styled(Share)`
  justify-content: center;
  grid-column: span 4;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, VerticalSpacingVariant.SMALL,{bottom:false})}

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`;