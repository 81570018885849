import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { ArticleHeadProps } from '.';
import { Share } from '../../common/share';

import { Container, Content, Title, MetaItems, MetaItem, MetaLink, Date, ShareButtons } from './head.styles';

export const ArticleHead = ({title,date,cover,category,section,url}:ArticleHeadProps) => {
  const image = cover ? getImage(cover.gatsbyImageData) : null;

  return (
    <Container>
      <Content>
        <MetaItems>
          {category && <MetaItem><MetaLink to={category.link}>{category.heading}</MetaLink></MetaItem>}
          {section && <MetaItem><MetaLink to={section.link}>{section.heading}</MetaLink></MetaItem>}
        </MetaItems>
        <Title>{title}</Title>
        <ShareButtons url={url} title={title} />
        <Date>{date}</Date>
      </Content>
    </Container>
  )
};