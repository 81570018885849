import React from 'react';
import { plainTextToHTML } from '../../../common/string';

import { Container, Content} from './introduction.styles';
import { ArticleIntroductionProps } from './introduction.types';


export const ArticleIntroduction = ({content}:ArticleIntroductionProps) => {

  return (
      <Container>
        <Content>
          {plainTextToHTML(content)}
        </Content>
      </Container>
  )

};