import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import { concat, get, map } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { getAbsoluteURL, getInternalURL } from '../common/link';
import { parseArticles, parseCategory, parseMedia, parseSection, parseTopics } from '../common/content';
import { ArticleHead } from '../components/article/head';
import { ArticleMedia } from '../components/article/media';
import { ArticleBody } from '../components/article/body';
import { ArticleFooter } from '../components/article/footer';
import { ArticleIntroduction } from '../components/article/introduction';
import { ArticleList } from '../components/article/list';
import { ArticleSectionLayout } from '../components/article/section/section.types';
import { getTheme, Theme } from '../theme/color';
import { ArticleSection } from '../components/article/section';
import { useLocale } from '../context/locale';
import { loadArticles } from '../services/datocms';
import { ArticleDynamicList } from '../components/article/dynamicList';
import { ThemeProvider } from 'styled-components';
import { useDictionary } from '../context/dictionary';
import { HeadingSize, HeadingVariant } from '../components/common/heading/heading.types';
import Category from './category';


export const query = graphql`
  query ArticleQuery($id: String!, $originalId: String!, $section: String, $category: String, $initialSize: Int!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsArticle(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      title
      description
      media{
        ... on DatoCmsMediaImage {
          model {
            apiKey
          }
          image {
            ...Cover
          }
        }
        ... on DatoCmsMediaVideo {
          model {
            apiKey
          }
          video {
            provider
            url
            width
            height
          }
        }
        ... on DatoCmsMediaAudio {
          model {
            apiKey
          }
          audio
        }
      }
      introduction
      body {
        value
        blocks {
          ...InlineQuote
          ...InlineTwitter
          ...InlineInstagram
          ...InlineIframe
          ...InlineMediaInternal
          ...InlineRelated
          ...InlineTwitter
          ...InlineYouTube
          ...InlineFact
        }
      }
      topics{
        name
        slug
      }
      category{
        model {
          apiKey
        }
        name
        slug
        theme
        featured
      }
      section{
        name
        slug
      }
    }
    versions: allDatoCmsArticle(
      filter: {slug: {ne: null}, originalId: {eq: $originalId}}
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
    categoryArticles: allDatoCmsArticle(
      filter: {slug: {ne: null}, category: {id: {eq: $category}}},
      sort: {fields: meta___firstPublishedAt, order: DESC},
      limit: 10
    ) {
      nodes {
        ...ArticleCard
      }
    }
    articles: allDatoCmsArticle(
      filter: {slug: {ne: null}, id: {ne: $id}, section: {id: {eq: $section}}},
      sort: {fields: meta___firstPublishedAt, order: DESC},
      limit: $initialSize
    ) {
      nodes {
        ...ArticleCard
      }
      totalCount
    }
  }
`;

const Article = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const categoryArticles = get(data, 'categoryArticles.nodes');
  const articles = get(data, 'articles.nodes');
  const articlesTotalCount = get(data, 'articles.totalCount',0);
  
  const versions = get(data, 'versions.nodes');

  if (!page || !settings || !site) return null;

  const locale = useLocale();

  const labelMore = useDictionary("labelMore");
  const headingRelated = useDictionary("headingRelated")

  const schema = getSchema({ settings, site, page, pageContext });
  const pageURL = getAbsoluteURL({url: settings.siteUrl, pageType:page.model.apiKey, locale:page.locale, slug:page.slug});

  const more = get(page, 'category.featured', false) ? {
    heading: get(page, 'category.name'),
    articles: parseArticles(categoryArticles),
    layout: ArticleSectionLayout.SLIDER,
    theme: get(page, 'category.theme'),
    link: getInternalURL({pageType:get(page,'category.model.apiKey'),locale,...get(page,'category')}),
  } : null;

  const date = new Date(page.meta.firstPublishedAt).toLocaleDateString(locale === "en" ? "en-GB" : locale);

  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={true}
        page={page}
        versions={versions}
      />


      <ArticleHead title={page.title} category={parseCategory(page.category,page.locale)} section={parseSection(page.section,page.locale)} date={date} url={pageURL} />
      <ArticleMedia content={parseMedia(page.media)} title={page.title} />
      {page.introduction && <ArticleIntroduction content={page.introduction} />}
      <ArticleBody content={page.body} />
      <ArticleFooter title={page.title} topics={parseTopics(page.topics,page.locale)} section={parseSection(page.section,page.locale)} date={page.meta.firstPublishedAtFormatted} url={pageURL} />
      {more && <ArticleSection {...more} />}
      {articles.length > 0 && <ArticleDynamicList heading={headingRelated} headingSize={HeadingSize.SMALL} headingVariant={HeadingVariant.SIMPLE} buttonLabel={labelMore} items={parseArticles(articles)} excludeOriginalIds={concat([pageContext.originalId],articles.map(a => a.originalId))} sectionOriginalId={pageContext.sectionOriginalId} total={Math.max(articlesTotalCount - pageContext.initialSize,0)} borderTop={!more} paginationSize={pageContext.paginationSize} />}
    </Fragment>
  );
};

export default Article;