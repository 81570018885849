import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { ArticleMediaProps } from '.';
import { VideoPlayer } from '../../common/videoPlayer';

import { Container, Content, Image, Video, Audio } from './media.styles';
import { ArticleMediaAudioProps, ArticleMediaImageProps, ArticleMediaVideoProps } from './media.types';

const ArticleMediaImage = ({asset,title}:ArticleMediaImageProps) => {
  const image = getImage(asset.gatsbyImageData);

  return (
    <Container>
      <Content>
        {image && <Image image={image} alt={asset.alt || title} />}
      </Content>
    </Container>
  )
}

const ArticleMediaVideo = ({asset}:ArticleMediaVideoProps) => {
  return (
    <Container>
      <Content>
        <Video {...asset} />
      </Content>
    </Container>
  )
}

const ArticleMediaAudio = ({asset}:ArticleMediaAudioProps) => {
  const url = new URL(asset);
  const parts = url.pathname.substring(1).split('/');
  url.pathname = "/" + ["embed-podcast"].concat(parts).join("/");

  return (
    <Container>
      <Content>
        <Audio src={url.toString()} width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media" />
      </Content>
    </Container>
  )
}


export const ArticleMedia = ({content,title}:ArticleMediaProps) => {
  switch(content.type){
    case "media_image": return <ArticleMediaImage title={title} {...content} />;
    case "media_video": return <ArticleMediaVideo {...content} />;
    case "media_audio": return <ArticleMediaAudio {...content} />;
  }

  return null;
};