import styled from 'styled-components';
import { getGutterAsPixels, getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from '../../../theme/layout';
import { ResponsiveMedia } from '../../common/responsiveMedia';

export const Container = styled.div`
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,VerticalSpacingVariant.SMALL)}
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-column: 3 / span 8;
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  background-color: ${({theme}) => theme.background.secondary};
  display: grid;
  grid-template-columns: repeat(2, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(ScreenSize.SMALL)};
  row-gap: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.SMALL)};
  margin: 0 ${getMarginAsPixels(ScreenSize.SMALL,{multiplier:-1})};
  padding: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.SMALL)} ${getMarginAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    column-gap: ${getGutterAsPixels(ScreenSize.MEDIUM)};
    row-gap: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.MEDIUM)};
    margin: 0 ${getMarginAsPixels(ScreenSize.MEDIUM,{multiplier:-1})};
    padding: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.MEDIUM)} ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    column-gap: ${getGutterAsPixels(ScreenSize.LARGE)};
    row-gap: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.LARGE)};
    margin: 0 ${getMarginAsPixels(ScreenSize.LARGE,{multiplier:-1})};
    padding: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.LARGE)} ${getMarginAsPixels(ScreenSize.LARGE)};
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    column-gap: ${getGutterAsPixels(ScreenSize.XLARGE)};
    row-gap: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.XLARGE)};
    margin: 0 ${getMarginAsPixels(ScreenSize.XLARGE,{multiplier:-1})};
    padding: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.LARGE)} ${getMarginAsPixels(ScreenSize.XLARGE)};
  }

  ${getMediaQuery(ScreenSize.MAX)}{
    column-gap: ${getGutterAsPixels(ScreenSize.MAX)};
    row-gap: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.MAX)};
    margin: 0 ${getMarginAsPixels(ScreenSize.MAX,{multiplier:-1})};
    padding: ${getVerticalSpacingAsPixels(VerticalSpacingVariant.SMALL, ScreenSize.MAX)} ${getMarginAsPixels(ScreenSize.MAX)};
  }
`;

export const Item = styled.div`
  grid-column: span 2;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: span 1;
  }
`

