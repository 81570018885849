import styled from 'styled-components';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from '../../../theme/layout';
import { ResponsiveMedia } from '../../common/responsiveMedia';

export const Container = styled.div`
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,VerticalSpacingVariant.SMALL)}
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-column: 3 / span 8;
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Media = styled(ResponsiveMedia)`
  
`;