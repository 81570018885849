import styled from "styled-components";
import { Color, getColor } from "../../../theme/color";
import { getMediaQuery, getUnitAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const Container = styled.div`
  position: relative;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,VerticalSpacingVariant.SMALL)}
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-column: 4 / span 6;
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: ${getUnitAsPixels({multiplier:2})} 0;
  border-top: ${({theme}) => `1px solid ${theme.background.secondary}` };
  border-bottom: ${({theme}) => `1px solid ${theme.background.secondary}` };

  ${getMediaQuery(ScreenSize.LARGE)}{
    padding: ${getUnitAsPixels({multiplier:3})} 0;
  }
`;

export const Text = styled.div`
  ${getTypography(Type.HEADING_4)}
  color: ${({theme}) => theme.foreground.primary};
  flex: 1 1 auto;
  padding-inline-start: ${getUnitAsPixels({multiplier:3})};

  strong {
    color: ${getColor(Color.BRIGHT_ORANGE)};
  }
`

export const IconContainer = styled.div`
  flex: 0 0 auto;
  width: ${getUnitAsPixels({multiplier:8})};
  height: ${getUnitAsPixels({multiplier:8})};
  border-radius: 50%;
  background-color: ${getColor(Color.BRIGHT_ORANGE)};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`

`