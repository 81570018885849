import styled from "styled-components";
import { getUnitAsPixels } from "../../../theme/layout";

export const Container = styled.ul`
display: flex;
`;

export const ShareItem = styled.li`
margin-inline-end: ${getUnitAsPixels()};

&:last-child{
  margin-inline-end: 0;
}
`;

export const SocialMediaIconCircle = styled.circle`
transition: fill 240ms ease-out;
`;

export const SocialMediaIconPath = styled.path`
transition: fill 240ms ease-out;
`;

export const SocialMediaIcon = styled.svg`
cursor: pointer;

${SocialMediaIconCircle} {
  fill: ${({theme}) => theme.foreground.tertiary};
}
${SocialMediaIconPath} {
  fill: ${({theme}) => theme.background.primary};
}

&:hover {
  ${SocialMediaIconCircle} {
    fill: ${({theme}) => theme.foreground.primary};
  }
  ${SocialMediaIconPath} {
    fill: ${({theme}) => theme.background.primary};
  }
}
`;