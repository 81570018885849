import styled from 'styled-components';

export const Container = styled.div<{ aspectRatio: number }>`
  position: relative;
  padding-top: ${({aspectRatio}) => aspectRatio * 100}%;
  width: 100%;
`;

export const Player = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`