import React from 'react';
import { stripScriptTags } from '../../../common/string';
import { DatoCmsInlineInstagram } from '../../../common/types/graphql';
import { Container, Content, Video } from './youtube.styles';

export const InlineYouTube = ({asset,...props}) => {
  return (
    <Container>
      <Video {...asset} />
    </Container>
  )
};