import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { CONTAINER, getMediaQuery, getUnitAsPixels, getVerticalSpacingAsStyle, GRID, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { VideoPlayer } from "../../common/videoPlayer";

export const Container = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL,{top:false})}
`

export const Content = styled.div`
  ${GRID}
`

export const Image = styled(GatsbyImage)`
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`

export const Video = styled(VideoPlayer)`
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`

export const Audio = styled.iframe`
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`