import styled from "styled-components";
import { getMediaQuery, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";

export const Container = styled.div<{ aspectRatio: number }>`
  position: relative;
  height: 0;
  padding-top: ${({aspectRatio}) => aspectRatio * 100}%;
  
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,VerticalSpacingVariant.SMALL)}
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: 1 / span 12;
  }
  
  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-column: 3 / span 8;
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Content = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;