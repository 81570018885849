import styled from "styled-components";
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";

export const Container = styled.div`
  position: relative;
  
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,VerticalSpacingVariant.SMALL)}
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-column: 4 / span 6;
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;