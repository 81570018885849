import styled from "styled-components"
import { CONTAINER, getMediaQuery, getUnitAsPixels, getVerticalSpacingAsStyle, GRID, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout"
import { getTypography, Type } from "../../../theme/typography"

export const Container = styled.div`
  ${CONTAINER}
`

export const Content = styled.div`
  ${GRID}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, VerticalSpacingVariant.SMALL, {top:false})}

  >p {
    ${getTypography(Type.BODY_LARGE)}
    margin-bottom: ${getUnitAsPixels({multiplier:1})};
    grid-column: span 4;

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      grid-column: 1 / span 12;
    }
    
    ${getMediaQuery(ScreenSize.LARGE)}{
      margin-bottom: ${getUnitAsPixels({multiplier:2})};
      grid-column: 4 / span 6;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
`