import YouTubePlayer from 'react-player/youtube';
import VimeoPlayer from 'react-player/vimeo';
import FacebookPlayer from 'react-player/facebook';
import React, { useState } from 'react'
import { Container, Player } from './videoPlayer.styles';
import { useLanguage } from '../../../context/locale';

const Content = ({provider,url,autoPlay=false}:{provider:string,url:string,autoPlay:boolean}) => {
  
  const language = useLanguage();

  const [playing,setPlaying] = useState(autoPlay);

  const config = {
    youtube:{
      playerVars: { 
        hl: language
      }
    }
  }

  switch(provider){
    case "youtube": return <Player as={YouTubePlayer} url={url} controls={true} width={'100%'} height={'100%'} playsinline playing={playing} config={config} />
    case "vimeo": return <Player as={VimeoPlayer} url={url} controls={true} width={'100%'} height={'100%'} playsinline playing={playing} />
    case "facebook": return <Player as={FacebookPlayer} url={url} controls={true} width={'100%'} height={'100%'} playsinline playing={playing} />
  }

  return null;
}

export const VideoPlayer = ({provider,url,width,height,className,autoPlay=false}:{provider:string,url:string,width:number,height:number,className?:string,autoPlay:boolean}) => {
  const aspectRatio = height / width;

  return (
    <Container className={className} aspectRatio={aspectRatio}>
      <Content provider={provider} url={url} autoPlay={autoPlay} />
    </Container>
  )
}