import { Link } from "gatsby";
import styled from "styled-components";
import ColorTransform from 'color';
import { Color, getColor } from "../../../theme/color";
import { CONTAINER, getMediaQuery, getUnitAsPixels, getVerticalSpacingAsStyle, GRID, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { Share } from "../../common/share";

export const Container = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, VerticalSpacingVariant.SMALL)}
`

export const Content = styled.div`
  ${GRID}
`

export const ShareButtons = styled(Share)`
  justify-content: center;
  grid-column: span 4;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, VerticalSpacingVariant.SMALL,{bottom:false})}

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`;

export const TagItems = styled.ul`
  text-align: center;
  grid-column: span 4;
  margin-bottom: ${getUnitAsPixels({multiplier:-1})};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 4 / span 6;
  }
`

export const TagItem = styled.li`
  ${getTypography(Type.HEADING_5)}
  display: inline-block;
  margin: 0 ${getUnitAsPixels({multiplier:0.5})} ${getUnitAsPixels()} ${getUnitAsPixels({multiplier:0.5})};
`

export const TagLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  background-color: ${({ theme }) => theme.background.secondary};
  color: ${({ theme }) => theme.foreground.primary};
  padding: ${getUnitAsPixels()};
  transition: background-color 0.3s;

  &:hover{
    background-color: ${ColorTransform(getColor(Color.LIGHT_GREY)).alpha(0.7).toString()};
  }
`;