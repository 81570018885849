import React from 'react';
import { StructuredText } from 'react-datocms';

import { Container, Content, Inner} from './body.styles';
import { ArticleBodyProps } from './body.types';
import { InlineInstagram } from '../../inline/instagram';
import { InlineQuote } from '../../inline/quote';
import { InlineIframe } from '../../inline/iframe';
import { InlineTwitter } from '../../inline/twitter';
import { InlineMediaInternal } from '../../inline/mediaInternal';
import { InlineRelated } from '../../inline/related';
import { InlineYouTube } from '../../inline/youtube';
import { InlineFact } from '../../inline/fact';

export const ArticleBody = ({content}:ArticleBodyProps) => {
  return (
      <Container>
        <Content>
          <Inner>
            <StructuredText
              data={content}
              renderBlock={({ record }) => {
                if(record.model){
                  switch(record.model.apiKey){
                    case 'inline_media_internal': return <InlineMediaInternal media={record.media} />;
                    case 'inline_instagram': return <InlineInstagram {...record} />;
                    case 'inline_related': return <InlineRelated {...record} />;
                    case 'inline_quote': return <InlineQuote {...record} />;
                    case 'inline_iframe': return <InlineIframe {...record} />;
                    case 'inline_twitter': return <InlineTwitter {...record} />;
                    case 'inline_youtube': return <InlineYouTube {...record} />;
                    case 'inline_fact': return <InlineFact {...record} />;
                  }
                }
                return null;
              }}
            />
          </Inner>
        </Content>
      </Container>
  )
};