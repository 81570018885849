import styled from "styled-components";
import Color from 'color';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const Container = styled.div`
  display: flex;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL)}
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    grid-column: 1 / span 12;
  }
  
  ${getMediaQuery(ScreenSize.LARGE)}{
    grid-column: 3 / span 8;
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  text-align: center;
`

export const Text = styled.div`
  ${getTypography(Type.HEADING_2)}
  color: ${({ theme }) => theme.foreground.primary};

  &:before{
    content:'“';
  }

  &:after{
    content:'”';
  }
`;

export const Caption = styled.div`
  ${getTypography(Type.LABEL)}
  margin-top: ${getUnitAsPixels({multiplier:3})};
  color: ${({ theme }) => Color(theme.foreground.secondary).alpha(0.5)};
`;