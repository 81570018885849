import React from 'react';
import { fbButton, tw, email, whatsapp } from 'vanilla-sharing';
import { Container, ShareItem, SocialMediaIcon, SocialMediaIconCircle, SocialMediaIconPath } from './share.styles';
import { ShareProps } from './share.types';

export const Share = ({url,title,className}:ShareProps) => {
  return (
    <Container className={className}>
      <ShareItem>
        <SocialMediaIcon width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => fbButton({ url })}>
          <SocialMediaIconCircle cx="18" cy="18" r="17.5" />
          <SocialMediaIconPath d="M26 18.0489C26 13.6036 22.4183 10 18 10C13.5817 10 10 13.6036 10 18.0489C10 22.0663 12.9255 25.3962 16.75 26V20.3755H14.7188V18.0489H16.75V16.2756C16.75 14.2584 17.9443 13.1441 19.7717 13.1441C20.647 13.1441 21.5625 13.3013 21.5625 13.3013V15.2821H20.5537C19.5599 15.2821 19.25 15.9025 19.25 16.539V18.0489H21.4688L21.1141 20.3755H19.25V26C23.0745 25.3962 26 22.0663 26 18.0489Z" />
        </SocialMediaIcon>
      </ShareItem>
      <ShareItem>
        <SocialMediaIcon width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => tw({ url, title })}>
          <SocialMediaIconCircle cx="18" cy="18" r="17.5" />
          <SocialMediaIconPath d="M25.7662 13.4206C25.2229 13.6618 24.639 13.8242 24.0263 13.8975C24.652 13.5228 25.1325 12.929 25.3583 12.2215C24.7732 12.5685 24.1247 12.8208 23.4344 12.9567C22.8825 12.3679 22.0943 12 21.2231 12C19.2672 12 17.83 13.8249 18.2717 15.7192C15.7547 15.5931 13.5226 14.3872 12.0281 12.5543C11.2344 13.9159 11.6165 15.6971 12.9651 16.5991C12.4692 16.5831 12.0016 16.4471 11.5937 16.2201C11.5605 17.6235 12.5664 18.9364 14.0234 19.2287C13.597 19.3443 13.13 19.3714 12.655 19.2804C13.0402 20.4838 14.1587 21.3593 15.4852 21.3839C14.2116 22.3825 12.6071 22.8285 11 22.639C12.3406 23.4986 13.9336 24 15.644 24C21.2687 24 24.4465 19.2496 24.2545 14.9889C24.8464 14.5613 25.3601 14.0279 25.7662 13.4206Z" />
        </SocialMediaIcon>
      </ShareItem>
      <ShareItem>
        <SocialMediaIcon width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => email({ url, subject: title })} >
          <SocialMediaIconCircle cx="18" cy="18" r="17.5" />
          <SocialMediaIconPath d="M19.9019 16.3C19.9488 16.2361 19.9709 16.1574 19.9642 16.0785C19.9575 15.9996 19.9224 15.9258 19.8654 15.8708C19.8084 15.8158 19.7333 15.7833 19.6542 15.7795C19.5751 15.7756 19.4972 15.8006 19.4351 15.8497L13.5822 20.8307L10.4832 19.6316C10.3487 19.5804 10.2318 19.4915 10.1464 19.3757C10.061 19.2599 10.0107 19.1219 10.0015 18.9783C9.99235 18.8347 10.0247 18.6914 10.0946 18.5657C10.1646 18.4399 10.2693 18.3369 10.3961 18.269L23.223 11.0819C23.324 11.0248 23.4387 10.9966 23.5546 11.0003C23.6706 11.0041 23.7832 11.0397 23.8802 11.1033C23.9773 11.1668 24.0549 11.2559 24.1047 11.3606C24.1545 11.4654 24.1745 11.5818 24.1625 11.6972L23.0264 23.427C23.0147 23.5459 22.9756 23.6605 22.9124 23.7619C22.8492 23.8633 22.7634 23.9487 22.6618 24.0116C22.5602 24.0744 22.4454 24.113 22.3265 24.1243C22.2075 24.1357 22.0876 24.1194 21.9759 24.0768L18.212 22.6211L15.8513 24.8347C15.7643 24.9165 15.6552 24.9709 15.5375 24.9912C15.4198 25.0114 15.2987 24.9967 15.1894 24.9487C15.08 24.9007 14.9872 24.8217 14.9223 24.7214C14.8575 24.6211 14.8236 24.5039 14.8248 24.3845V22.6526L19.9019 16.3Z" />
        </SocialMediaIcon>
      </ShareItem>
      <ShareItem>
        <SocialMediaIcon width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => whatsapp({ url, title })}>
          <SocialMediaIconCircle cx="18" cy="18" r="17.5" />
          <SocialMediaIconPath d="M18.0019 10H17.9978C13.5869 10 10 13.5881 10 18C10 19.75 10.5641 21.3719 11.5231 22.6891L10.5263 25.6609L13.6012 24.6778C14.8659 25.5159 16.375 26 18.0019 26C22.4131 26 26 22.4109 26 18C26 13.5891 22.4131 10 18.0019 10ZM22.1131 20.9128C21.9431 21.3928 21.2684 21.7913 20.73 21.9075C20.3619 21.9859 19.8806 22.0484 18.2616 21.3772C16.1903 20.5191 14.8566 18.4147 14.7528 18.2781C14.6531 18.1416 13.9159 17.1638 13.9159 16.1525C13.9159 15.1413 14.4294 14.6488 14.6366 14.4372C14.8066 14.2638 15.0875 14.1844 15.3572 14.1844C15.4444 14.1844 15.5228 14.1887 15.5931 14.1922C15.8 14.2009 15.9041 14.2134 16.0406 14.5403C16.2106 14.95 16.6247 15.9612 16.6741 16.0653C16.7244 16.1694 16.7744 16.3103 16.7041 16.4469C16.6381 16.5878 16.58 16.6503 16.4759 16.7703C16.3719 16.8903 16.2734 16.9819 16.1694 17.1103C16.0744 17.2222 15.9669 17.3419 16.0866 17.5491C16.2063 17.7516 16.6203 18.4275 17.23 18.97C18.0166 19.6703 18.6544 19.8941 18.8825 19.9894C19.0525 20.06 19.255 20.0431 19.3794 19.9109C19.5372 19.7409 19.7319 19.4591 19.93 19.1816C20.0709 18.9825 20.2487 18.9578 20.4356 19.0281C20.6259 19.0941 21.6328 19.5919 21.84 19.695C22.0469 19.7991 22.1834 19.8484 22.2338 19.9356C22.2831 20.0222 22.2831 20.4316 22.1131 20.9128Z" />
        </SocialMediaIcon>
      </ShareItem>
    </Container>
  )
}