import React from 'react';
import { parseArticles } from '../../../common/content';
import { ArticleCard } from '../../article/card';
import { Container, Content, Item } from './related.styles';

export const InlineRelated = ({articles,...props}) => {
  
  return (
    <Container>
      <Content>
        {parseArticles(articles).map((props) => <Item><ArticleCard {...props} /></Item>)}  
      </Content>
    </Container>
  )
};