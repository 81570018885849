import React from 'react'
import { getImage } from "gatsby-plugin-image"

import { getImageSizes } from '../../../theme/layout'

import { DefaultImage, FallbackImage, PortraitImage } from './responsiveImage.styles'


export const ResponsiveImage = ({defaultSource,portraitSource,fit,horizontalAlign,verticalAlign,sizes,sizeOptions,className,onClick,container}) => {
	const defaultImage = defaultSource?.gatsbyImageData || defaultSource?.landscape;
	const portraitImage = portraitSource?.gatsbyImageData || defaultSource?.portrait;
	const imageSizes = getImageSizes(sizes,sizeOptions);

	if(defaultImage){
		return (
			<>
				<DefaultImage forwardedAs={container} onClick={onClick} className={className} image={defaultImage} sizes={imageSizes} draggable={false} objectFit={fit} objectPosition={`${horizontalAlign === "left" ? '0%' : (horizontalAlign === "right" ? "100%" : "50%")} ${verticalAlign === "top" ? '0%' : (verticalAlign === "bottom" ? "100%" : "50%")}`} draggable={false}  hideOnPortrait={!!portraitImage} alt={defaultSource?.alt || ""} />
				{portraitImage && <PortraitImage forwardedAs={container} onClick={onClick} className={className} image={portraitImage} sizes={imageSizes} draggable={false} objectFit={fit} objectPosition={`${horizontalAlign === "left" ? '0%' : (horizontalAlign === "right" ? "100%" : "50%")} ${verticalAlign === "top" ? '0%' : (verticalAlign === "bottom" ? "100%" : "50%")}`} draggable={false} alt={portraitSource?.alt || defaultSource?.alt || ""} />}
			</>
		)
	}
	else if(defaultSource.url){
		return <FallbackImage onClick={onClick} className={className} src={defaultSource.url} draggable={false} />
	}

	return null;
}