import { get } from 'lodash';
import React from 'react';
import { Container, Content, Icon, IconContainer, Text } from './fact.styles';

export const InlineFact = ({textNode,factType}) => {

  const icon = get(factType,"icon.url");
  const text = get(textNode,"childMarkdownRemark.html");

  return (
    <Container>
      <Content>
        {icon && <IconContainer><Icon src={icon} /></IconContainer>}
        {text && <Text dangerouslySetInnerHTML={{__html:text}} />}
      </Content>
    </Container>
  )
};