import React from 'react';
import { stripScriptTags } from '../../../common/string';
import { DatoCmsInlineInstagram } from '../../../common/types/graphql';
import { Container, Content } from './instagram.styles';

export const InlineInstagram = ({embed,...props}:DatoCmsInlineInstagram) => {
  return (
    <Container>
      <Content dangerouslySetInnerHTML={{__html: stripScriptTags(embed)}} />
    </Container>
  )
};